import React from 'react';
import './article.css';

const Article = ({ date, text, author }) => (
  <div className="gpt3__cta-container_article">
      <div className="gpt3__cta-container_article-content">
        <div>
          <p>{date}</p>
          <h3>{text}</h3>
          <h4>{author}</h4>
        </div>
        <a href='https://www.facebook.com/KindomEliteMovers/reviews/?ref=page_internal'>
          <p className='gradient__text'>Check out this review</p>
        </a>
      </div>
  </div>
);

export default Article;