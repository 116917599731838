import React from 'react';
import './header.css';
import people from '../../assets/people.png';
import headshot from '../../assets/headshot.png';

const Header = () => {
  return (
    <div className='gpt3__header section__padding' id='home'>
        <div className='gpt3__header-content'>
          <h1 className='gradient__text'>Lets move all of your needs with breeze! </h1>
          <p>We take the weight off your shoulders and move all of your belongings to any desired location, you tell us what, where, and when and we take care of the rest!</p>
          <div className='gpt3__header-content__input'>
            <a href='#socials'>
              <button type='button'>Lets Chat!</button>
            </a>
          </div>
          <div className='gpt3__header-content__people'>
            <img src={people} alt='people'/>
            <a href='https://www.facebook.com/KindomEliteMovers/reviews/?ref=page_internal'>
              <p className='gradient__text'>Make sure to check out our reviews and see why we should be your next move!</p>
            </a>
          </div>
        </div>
        <div className='gpt3__header-image'>
          <img src={headshot} alt='headshot'/>
        </div>
    </div>
  )
}

export default Header