import React from 'react'
import { Footer, Blog, WhatGPT3, Header, Features } from './containers';
import {Brand, Navbar, CTA } from './components';
import './App.css'


const App = () => {
  return (
    <div className='App'>
        <div className='gradient__bg'>
            <Navbar />
            <Header />
        </div>
        <Blog />
        <WhatGPT3 />
        <CTA />
        <Brand />
        <Features />
        <Footer />
    </div>
  )
}

export default App