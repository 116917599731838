import React from 'react';
import { Facebook, Messenger, Instagram, LinkedIn, Mail, WhatsApp, Chat } from './imports';
import './brand.css';

const Brand = () => {
  return (
    <section id='socials'>
      <div>
        <div className='gpt3_cont section__padding'>
          <div className='gpt3__brand-content'>
              <h1 className='gradient__text'>Connect with me on my socials to get in touch!</h1>
          </div>
        </div>
        <div className='gpt3__brand section__padding'>
            <div>
              <a href='https://www.facebook.com/KindomEliteMovers'>
                <img src={Facebook} alt='Facebook' />
              </a>
            </div>
            <div>
              <a href='https://m.me/KingdomEliteMovers'>
                <img src={Messenger} alt='Messenger' />
              </a>
            </div>
            <div>
              <a href='https://www.instagram.com/kingdomelitemover/'>
                <img src={Instagram} alt='Instagram' />
              </a>
            </div>
            <div>
              <a href='mailto:kingdomelitemovers@gmail.com'>
                <img src={Mail} alt='Mail' />
              </a>
            </div>
            <div>
              <a href='https://wa.me/+18064108548'>
                <img src={WhatsApp} alt='Whatsapp' />
              </a>
            </div>
            <div>
              <a href='https://www.linkedin.com/in/johnny-ramirez-1877ab244/'>
                <img src={LinkedIn} alt='LinkedIn' />
              </a>
            </div>
            <div>
              <a href='sms:+18064108548'>
                <img src={Chat} alt='Chat' />
              </a>
            </div>
        </div>
      </div>
    </section>
  )
}

export default Brand