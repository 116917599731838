import React from 'react';
import kingdom10 from '../../assets/kingdom10.svg';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <a href='tel:+18064108548'>
        <h1 className="gradient__text">Ready to get moving? Get your FREE quote today! 806.410.8548</h1>
      </a>
    </div>

    <div className="gpt3__footer-links">

      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
      </div>

      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <a href='tel:+18064108548'>
          <p>806.410.8548</p>
        </a>
        <a href='mailto:kingdomelitemovers@gmail.com'>kingdomelitemovers@gmail.com</a>
      </div>

      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <a href='#socials'>Social Media</a>
      </div>

    </div>

    <div className="gpt3__footer-links_logo">
      <img src={kingdom10} alt="kingdom10" />
    </div>
    <div className="gpt3__footer-copyright">
      <p>@2022 Kingdom Elite Movers. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;