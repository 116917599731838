import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => {
  return (
    <div className='gpt3__whatgpt3 section__margin' id='possibility'>
        <div className="gpt3__whatgpt3-heading">
          <h1 className="gradient__text">Services we offer!</h1>
        </div>
        <div className="gpt3__whatgpt3-container">
          <Feature title="Residential" />
          <Feature title="Commercial" />
          <Feature title="Business" />
          <Feature title="Local" />
          <Feature title="Long-Distance" />
          <Feature title="Townhomes" />
          <Feature title="Apartments" />
          <Feature title="Storages" />
          <Feature title="Cleanout Services" />
          <Feature title="Furniture Removal" />
        </div>
    </div>
  )
}

export default WhatGPT3