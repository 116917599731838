import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.css';
import kingdom10 from '../../assets/kingdom10.svg';

const Menu = () => (
  <>
  <p className='gradient__text_nav'><a href='#home'>Home</a></p>
  <p className='gradient__text_nav'><a href='#whatgpt3'>About us</a></p>
  <p className='gradient__text_nav'><a href='#possibility'>Services</a></p>
  <p className='gradient__text_nav'><a href='#cta'>Reviews</a></p>
  {/* <p><a href='#blog'>Library</a></p> */}
  </>
)

// BEM -> Block Element Modifier

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className='gpt3__navbar'>
      <div className='gpt3__navbar-links'>
        <div className='gpt3__navbar-links_logo'>
          <img src={kingdom10} alt='kingdom10' />
        </div>
        <div className='gpt3__navbar-links_container'>
          <Menu />
        </div>
      </div>
      <div className='gpt3__navbar-sign'>
        <a href='tel:+18064108548'>
          <p>(806)-410-8548</p>
        </a>
        <a href='#socials'>
          <button>Free Quote</button>
        </a>
      </div>
      <div className='gpt3__navbar-menu'>
        {toggleMenu
          ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
          <div className='gpt3__navbar-menu_container scale-up-center'>
            <div className='gpt3__navbar-menu_container-links'>
              <Menu />
              <div className='gpt3__navbar-menu_container-links-sign'>
                <p>(806)-410-8548</p>
                <a href='#socials'>
                <button>Free Quote</button>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar