import React from 'react';
import './blog.css';
import about from '../../assets/about.png';

const Blog = () => {
  return (
    <div className='gpt3__blog section__padding' id='whatgpt3'>
        <div className='gpt3__blog-image'>
          <img src={about} alt='about'/>
        </div>
        <div className='gpt3__blog-content'>
          <h1 className='gradient__text'>Get to know our story</h1>
          <p>As a proud locally owned company in Amarillo, TX, Kingdom Elite Movers is here to serve people in any way possible when it comes to moving! We have years of experience and would like to take the stress off your next move. Our company takes pride in ourselves and in our work ethic that we provide. We strive hard to get your job done not only as quick as possible but with care and diligence. As a group of believers, our team aims to join one another as an elite! Together our crew is unstopable and without the blessing from our Savior, we wouldn’t be able to get the job done without him! We work with businesses, town homes, small homes, apartments, storages, you name it! We provide in AND out of town moves as well. My name is Johnny Ramirez and I would love for you to contact us today to get a free quote and book your next move. Having our customers kick back and relax while we execute the job is our motto so.. Kick back your feet and watch the Elite!</p>
        </div>
    </div>
  )
}

export default Blog