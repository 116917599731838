import React from 'react';
import './features.css';
import { useRef } from 'react';
import emailjs from 'emailjs-com';

const Features = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_08w5e5l', 'template_glq6req', e.target, '9f2caSIsBIlJXNFod')

    e.target.reset()
      // .then((result) => {
      //     console.log(result.text);
      // }, (error) => {
      //     console.log(error.text);
      // });
  };
  return (

    <div className='container contact__container'>
        <form ref={form} onSubmit={sendEmail}>
          <input type='text' name='name' placeholder='Name' required />
          <input type='text' name='number' placeholder='Phone number' required />
          <input type='email' name='email' placeholder='Email' required />
          <textarea name='message' rows='10' placeholder='Message..' required ></textarea>
          <button type='submit' className='btn btn-primary'>Send Message!</button>
        </form>
    </div>
  )
}

export default Features