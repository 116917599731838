import React from 'react';
import Article from '../../components/article/Article';
import './cta.css';

const CTA = () => (
  <div className="gpt3__cta section__padding" id="cta">
    <div className="gpt3__cta-heading">
      <h1 className="gradient__text">Check out what people have to say!</h1>
    </div>
    <div className="gpt3__cta-container">
      <div className="gpt3__cta-container_groupA">
        <Article date="Jul 03, 2022" text="Shout out to Johnny Ramirez and his crew for moving me from Amarillo Tx to Oklahoma City Oklahoma! They did a great job and moved everything while I just relax! If you are needing moving from state to state I highly recommend Kingdom Elite. Great customer service and low prices!" author ='- Jenny'/>
      </div>
      <div className="gpt3__cta-container_groupB">
        <Article date="Jun 29, 2022" text="They was very professional about moving my client. They were on time and my client is elderly. They are caring and respectful. I highly recommend these movers. I will use them again." author ='- Rexanna'/>
        <Article date="Jun 30, 2022" text="Johnny and his crew did an amazing job and I highly recommend these guys again! They moved me and my familys furniture and did an outstanding job!" author='- Lj'/>
        <Article date="Jun 24, 2022" text='Johnny is a hard worker and gets the job done. Very friendly and easy to work with. Highly recommended!' author ='- Nathan'/>
        <Article date="Jun 30, 2022" text="Johnnys company did an outstanding job on my move. Professional and efficient, I highly recommend." author='- April'/>
      </div>
    </div>
    <div className="gpt3__cta-footer">
      <a href='https://www.facebook.com/KindomEliteMovers/reviews/?ref=page_internal'>
        <h1 className="gradient__text">Click here to see more reviews like these!</h1>
      </a>
    </div>
  </div>
);

export default CTA;